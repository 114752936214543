// import React from 'react';
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { RootLoaderData } from "./LoaderData";
// import { redirect, useNavigate } from "react-router-dom";


export default function AppHeader() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { session } = useLoaderData() as RootLoaderData;

  const settings = [
    session == null ? { text: "Login", href: "/account/login" } : undefined,
    session == null ? { text: "Register", href: "/account/register" } : undefined,
    session != null ? { text: "Account", href: "/account/check" } : undefined,
    session != null ? { text: "Log out", href: "/account/logout" } : undefined,
  ]

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="a" sx={{ flexGrow: 1 }}
            onClick={() => { navigate("/"); }}
            style={{ color: 'white', textDecoration: 'none' }}>
            MILC Account
          </Typography>

          {/* <Button color="inherit" href="/login">Login</Button> */}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={(event) => { setAnchorEl(event.currentTarget); }} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => { setAnchorEl(null); }}
            >
              {settings.filter(i => Boolean(i)).map(item => (
                <MenuItem key={item!.text} onClick={() => { navigate(item!.href); setAnchorEl(null); }}>
                  <Typography textAlign="center">{item!.text}</Typography>
                </MenuItem>))}

            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}