import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import './index.css'
// import AppHeader from './layout/header.tsx';
// import { getPageTree } from './router.tsx';
import { Backdrop } from '@mui/material';
import routes from './layout/';
// import history from 'history';

// const router = createBrowserRouter(getPageTree());
const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider
      router={router}
      fallbackElement={<Backdrop sx={{ color: '#fff' }} open={true}></Backdrop>}
      future={{ v7_startTransition: true }} />
  </React.StrictMode>,
)
